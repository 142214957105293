/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import React from 'react';
import { Button, ChangeContext, Form, Input, Select, Switch, Text } from 'tea-component';
import { useForm, useField } from 'react-final-form-hooks';
import { Subscribe } from '@src/models';
import validate, { getMessage } from '@src/utils/validate';
import FileUpload from '../FileUpload';
import style from './index.module.scss';
import { AuthTypeOptions, HashTypeOptions } from '@src/utils/enums';

const vilidateForm = ({
  ChainId,
  AuthType,
  Addr,
  OrgId,
  Tls,
  OrgCA,
  UserCert,
  UserKey,
  TLSHostName,
  HashType,
}: Subscribe) => {
  const checktls =
    !TLSHostName || validate.isEmptyString(TLSHostName) ? '请输入TLSHostName' : validate.TLSHostName(TLSHostName);
  if (AuthType === 'permissionedwithcert') {
    return {
      ChainId: ChainId ? validate.chainId(ChainId) : '请输入区块链Id',
      // AuthType: validate.isEmptyString(AuthType) ? '请选择帐号模式' : '',
      Addr: Addr ? validate.Addr(Addr) : '请输入节点和端口号',
      OrgId: OrgId ? validate.orgId(OrgId) : '请输入组织Id',
      OrgCA: Tls && (!OrgCA || validate.isEmptyString(OrgCA)) ? '请上传组织CA证书' : undefined,
      UserCert: !UserCert || validate.isEmptyString(UserCert) ? '请上传用户证书' : undefined,
      UserKey: validate.isEmptyString(UserKey) ? '请上传用户私钥' : undefined,
      // eslint-disable-next-line no-nested-ternary
      TLSHostName: Tls ? checktls : undefined,
    };
  }
  return {
    ChainId: ChainId ? validate.chainId(ChainId) : '请输入区块链Id',
    Addr: Addr ? validate.Addr(Addr) : '请输入节点和端口号',
    UserKey: validate.isEmptyString(UserKey) ? '请上传用户私钥' : undefined,
    HashType: !HashType ? '请选择密码算法' : undefined,
  };
};
export default function SubscribeForm({
  onSubmit,
  onCancel,
  initialData,
  submitText,
  cancelText,
}: {
  onSubmit: (value: Subscribe, context?: ChangeContext<React.SyntheticEvent<Element, Event>>) => void;
  onCancel: () => void;
  initialData?: {
    ChainId: string;
    AuthType: string;
  };
  submitText?: string;
  cancelText?: string;
}) {
  const { form, handleSubmit, validating, submitting } = useForm({
    onSubmit: (value) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { ChainId, AuthType, Addr, OrgId, Tls, OrgCA, UserCert, UserKey, TLSHostName, HashType } = value;
      if (AuthType === 'permissionedwithcert') {
        if (Tls) {
          onSubmit({
            ChainId,
            AuthType,
            Addr,
            OrgId,
            Tls,
            OrgCA,
            UserCert,
            UserKey,
            TLSHostName,
          });
        } else {
          onSubmit({
            ChainId,
            AuthType,
            Addr,
            OrgId,
            Tls,
            UserCert,
            UserKey,
          });
        }
      } else {
        onSubmit({
          ChainId,
          AuthType,
          Addr,
          UserKey,
          HashType: Number(HashType),
        });
      }
    },
    initialValuesEqual: () => true,
    initialValues: {
      ChainId: initialData?.ChainId || '',
      AuthType: initialData?.AuthType || 'permissionedwithcert',
      Addr: '',
      OrgId: '',
      Tls: true,
      OrgCA: '',
      UserCert: '',
      UserKey: '',
      TLSHostName: 'chainmaker.org',
      HashType: '',
    },
    validate: vilidateForm,
  });
  const chainId = useField('ChainId', form);
  const authType = useField('AuthType', form);
  const addr = useField('Addr', form);
  const orgId = useField('OrgId', form);
  const orgCA = useField('OrgCA', form);
  const userCert = useField('UserCert', form);
  const userKey = useField('UserKey', form);
  const tLSHostName = useField('TLSHostName', form);
  const tls = useField('Tls', form);
  const hashType = useField('HashType', form);
  return (
    <form onSubmit={handleSubmit} className={style.content}>
      <Form>
        <Form.Item required label="区块链ID" {...getMessage(chainId)}>
          {!initialData?.ChainId && <Input {...chainId.input} placeholder="请输入区块链Id" />}
          {initialData?.ChainId && <Text theme="label">{initialData.ChainId}</Text>}
        </Form.Item>
        <Form.Item required label="帐号模式" {...getMessage(authType)}>
          {!initialData?.AuthType && (
            <Select
              appearance="button"
              style={{
                width: 450,
                height: 40,
                lineHeight: 40,
                fontSize: 14,
              }}
              matchButtonWidth={true}
              options={AuthTypeOptions}
              {...authType.input}
              placeholder="请选择账户模式"
            />
          )}
          {initialData?.AuthType && <Text theme="label">{initialData.AuthType}</Text>}
        </Form.Item>
        <Form.Item
          required
          label="节点RPC地址"
          {...getMessage(addr)}
          tips="请输入要订阅的链所在的服务器外网IP地址，及对外开放的RPC端口。请注意不能填127.0.0.1，长安链默认的RPC端口为12301-12304。"
        >
          <Input {...addr.input} placeholder="请输入节点Ip和端口" />
        </Form.Item>
        {authType.input.value === 'permissionedwithcert' && (
          <>
            <Form.Item required label="组织ID" {...getMessage(orgId)}>
              <Input {...orgId.input} placeholder="请输入组织Id" />
            </Form.Item>
            <Form.Item required label="是否开启TLS" {...getMessage(tls)}>
              <Switch {...tls.input}>{tls.input.value ? '开启' : '关闭'}</Switch>
            </Form.Item>
            {tls.input.value && (
              <>
                <Form.Item
                  required
                  label="TLSHostName"
                  {...getMessage(tLSHostName)}
                  tips="如无自定义修改过CA服务，则长安链默认的TLS_Host_Name为chainmaker.org。"
                >
                  <Input {...tLSHostName.input} placeholder="请输入TLSHostName" />
                </Form.Item>
                <Form.Item required label="组织证书" {...getMessage(orgCA)}>
                  <FileUpload value={orgCA.input.value} name="组织证书" accept=".crt" onChange={orgCA.input.onChange} />
                </Form.Item>
              </>
            )}
            <Form.Item required label="用户证书" {...getMessage(userCert)}>
              <FileUpload
                value={userCert.input.value}
                name="用户证书"
                accept=".crt"
                onChange={userCert.input.onChange}
              />
            </Form.Item>
          </>
        )}
        {authType.input.value === 'public' && (
          <Form.Item required label="密码算法" {...getMessage(hashType)}>
            <Select
              appearance="button"
              style={{
                width: 450,
                height: 40,
                lineHeight: 40,
                fontSize: 14,
              }}
              matchButtonWidth={true}
              options={HashTypeOptions}
              {...hashType.input}
              placeholder="请选择密码算法"
            />
          </Form.Item>
        )}
        <Form.Item required label="用户私钥" {...getMessage(userKey)}>
          <FileUpload value={userKey.input.value} name="用户私钥" accept=".key" onChange={userKey.input.onChange} />
        </Form.Item>
      </Form>
      {authType.input.value === 'permissionedwithcert' && (
        <div className={style.rule}>
          <div className={style.rule_i}>
            1.请确保本处上传的组织证书、用户证书、用户私钥、节点信息都属于要订阅链，且节点信息和用户信息都归属于所填写的组织名下。
          </div>
          <div className={style.rule_i}>2.推荐填写所要订阅的链的共识节点的IP和端口，并确保网络通畅。 </div>
          <div className={style.rule_i}>3.如果您的节点同时参与了多条链，请根据chainID区分你要订阅具体哪条链。</div>
          <div className={style.rule_i}>{`4.如果出现了"订阅链失败，请检查您所填写的信息是否正确再重试"，请检查：`}</div>
          <div className={style.rule_i_d}>- IP和RPC端口是否开放、是否正确。</div>
          <div className={style.rule_i_d}>- TLS是否选择正确，TLSHostName是否正确。</div>
          <div className={style.rule_i_d}>- 组织证书和所选用户证书是否为同一套。</div>
          <div className={style.rule_i_d}>- 用户证书尽量保证使用SIGN证书。</div>
          <div className={style.rule_i_d}>- 区块链ID是否正确。</div>
        </div>
      )}
      {authType.input.value === 'public' && (
        <div className={style.rule}>
          <div className={style.rule_i}>1. 推荐填写所要订阅的链的共识节点的IP和RPC端口，并确保网络通畅。</div>
          <div className={style.rule_i}>2. 如果您的节点同时参与了多条链，请根据区块链ID区分你要订阅具体哪条链。</div>
          <div className={style.rule_i}>3. 请选择正确的算法和正确的账户模式。</div>
        </div>
      )}
      {(cancelText || submitText) && (
        <Form.Action>
          {cancelText && (
            <Button
              style={{ height: 40, width: 120, fontSize: 16, marginRight: 80 }}
              type="weak"
              htmlType="button"
              onClick={onCancel}
            >
              {cancelText}
            </Button>
          )}
          {submitText && (
            <Button
              style={{ height: 40, width: 120, fontSize: 16 }}
              type="primary"
              htmlType="submit"
              loading={submitting}
              disabled={validating}
            >
              {submitText}
            </Button>
          )}
        </Form.Action>
      )}
    </form>
  );
}
