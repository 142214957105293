/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

const base64Chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'.split('');
// 解码不出汉字
export const transformBase64ToStr = (base: string): string => {
  try {
    const decode = atob(base);
    const str = decodeURI(decode);
    return str;
  } catch (e) {
    return base;
  }
};

export const base64decode = (str: string): string => {
  try {
    const bc = str.replace(/=/g, '').split('');
    let c;
    let b;
    let bs = '';
    bc.forEach((item) => {
      c = '';
      for (let z = 0, l = base64Chars.length; z < l; z++) {
        if (item === base64Chars[z]) {
          c = z;
          break;
        }
      }
      if (c === '') {
        // throw new Error('编码有误');
        return str;
      }
      b = Number(c).toString(2);
      if (b.length < 6) {
        b = `00000${b}`.slice(-6);
      }
      bs += b;
    });
    const j = Math.floor(bs.length / 8);
    let i;
    let ha = '';
    let hb;
    for (i = 0; i < j; i++) {
      hb = parseInt(bs.slice(i * 8, i * 8 + 8), 2).toString(16);
      if (hb.length < 2) hb = `00${hb}`.slice(-2);
      ha += `%${hb}`;
    }
    return decodeURIComponent(ha);
  } catch (e) {
    return str;
  }
};

export const formatNumberToThousands = (num: string | number | undefined | null): string => {
  if (!num) {
    return '0';
  }
  const count = `${num}`;
  const list = count.split('').reverse();
  let res = '';
  const l = list.length - 1;
  list.forEach((item, index) => {
    res = item + res;
    if ((index + 1) % 3 === 0 && l !== index) {
      res = `,${res}`;
    }
  });
  return res;
};
// 截取
export const formatHashDetail = (str: string) => {
  if (str.length <= 43) {
    return str;
  }
  return `${str.slice(0, 14)}...${str.slice(str.length - 26)}`;
};
