/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import React, { useEffect, useMemo, useState } from 'react';
import PageBox from '@components/ui/PageBox';
import { formatUnixTime, base64decode } from '@src/utils/tools';
import detailstyle from '../detail.module.scss';
import liststyle from '../list.module.scss';
import { GetTxDetail } from '@src/utils/apis';
import { Form, Table } from 'tea-component';
import { useParams } from 'react-router';
import { TxInfo } from '@src/models';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootReducer } from '@src/store';
export default function TransactionDetail() {
  const { txId } = useParams();
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId = useMemo(() => currentChain?.ChainId, [currentChain]);
  const authType = useMemo(() => currentChain?.AuthType, [currentChain]);
  const [detail, setDetail] = useState<TxInfo | null>(null);
  useEffect(() => {
    GetTxDetail({ ChainId: chainId, TxId: txId }).then((res) => {
      if (res.Data) {
        res.Data.ContractParametersList = JSON.parse(res.Data.ContractParameters);
        res.Data.ContractReadList = JSON.parse(res.Data.ContractRead);
        res.Data.ContractWriteList = JSON.parse(res.Data.ContractWrite);
        setDetail(res.Data);
      }
    });
  }, [chainId, txId]);
  return (
    <PageBox title="交易详情">
      <div className={detailstyle.detail}>
        <Form.Title>所属区块信息</Form.Title>
        <Form hideLabel={false} fixedLabelWidth={100} layout="fixed">
          <Form.Item label="区块哈希">
            <Form.Text>
              {detail?.BlockHash && detail?.BlockHeight ? (
                <Link to={`/${chainId}/block/${detail.BlockHeight}`}>{detail.BlockHash}</Link>
              ) : (
                detail?.BlockHash
              )}
              {!detail?.BlockHash && '--'}
            </Form.Text>
          </Form.Item>
          <Form.Item label="区块高度">
            <Form.Text>
              {detail?.BlockHeight ? (
                <Link to={`/${chainId}/block/${detail.BlockHeight}`}>{detail.BlockHeight}</Link>
              ) : (
                '--'
              )}
            </Form.Text>
          </Form.Item>
        </Form>
        <Form.Title>交易信息</Form.Title>
        <Form hideLabel={false} fixedLabelWidth={100} layout="fixed">
          <Form.Item label="交易id">
            <Form.Text>{detail?.TxId || '--'}</Form.Text>
          </Form.Item>
          <Form.Item label="交易类型">
            <Form.Text>{detail?.TxType || '--'}</Form.Text>
          </Form.Item>
          <Form.Item label="交易状态">
            <Form.Text>{detail?.TxStatusCode || '--'}</Form.Text>
          </Form.Item>
          {authType === 'permissionedwithcert' ? (
            <>
              <Form.Item label="交易发送组织">
                <Form.Text>{detail?.OrgId || '--'}</Form.Text>
              </Form.Item>
              <Form.Item label="交易发起用户">
                <Form.Text>{detail?.Sender || '--'}</Form.Text>
              </Form.Item>
              <Form.Item label="发起用户地址">
                <Form.Text>{detail?.UserAddr || '--'}</Form.Text>
              </Form.Item>
            </>
          ) : (
            <Form.Item label="交易发起用户">
              <Form.Text>{detail?.UserAddr || '--'}</Form.Text>
            </Form.Item>
          )}
          <Form.Item label="交易发起时间">
            <Form.Text>{detail?.Timestamp ? formatUnixTime(detail.Timestamp) : '--'}</Form.Text>
          </Form.Item>
        </Form>
        <Form.Title>合约执行信息</Form.Title>
        <Form hideLabel={false} fixedLabelWidth={100} layout="fixed">
          <Form.Item label="目标合约">
            <Form.Text>
              {detail?.ContractName ? (
                <Link to={`/${chainId}/contract/${detail.ContractName}`}>{detail.ContractName}</Link>
              ) : (
                '--'
              )}
            </Form.Text>
          </Form.Item>
          <Form.Item label="合约读写集哈希">
            <Form.Text>{detail?.RwSetHash || '--'}</Form.Text>
          </Form.Item>
          <Form.Item label="合约执行结果码">
            <Form.Text>{detail?.ContractResultCode === 0 ? 'ok' : 'fail'}</Form.Text>
          </Form.Item>

          <Form.Item label="合约执行信息">
            <Form.Text>{detail?.ContractMessage || '--'}</Form.Text>
          </Form.Item>
          <Form.Item label="合约调用方法">
            <Form.Text>{detail?.ContractMethod || '--'}</Form.Text>
          </Form.Item>
          {!detail?.ContractParametersList?.length || (
            <Form.Item label="合约调用入参">
              <Table
                className={liststyle.table}
                compact={false}
                records={detail?.ContractParametersList || []}
                recordKey="key"
                bordered={true}
                disableTextOverflow={true}
                columns={[
                  {
                    key: 'index',
                    header: '#',
                    align: 'left',
                    width: 60,
                    render: (item, name, index) => index + 1,
                  },
                  {
                    key: 'key',
                    header: 'Key',
                    align: 'left',
                    width: 260,
                  },
                  {
                    key: 'value',
                    header: 'Value',
                    align: 'left',
                    render: ({ value }) => <div className={liststyle.value_td}>{base64decode(value)}</div>,
                  },
                ]}
              />
            </Form.Item>
          )}
          {!detail?.ContractReadList?.length || (
            <Form.Item label="合约读集">
              <Table
                className={liststyle.table}
                compact={false}
                records={detail?.ContractReadList || []}
                recordKey="key"
                bordered={true}
                disableTextOverflow={true}
                columns={[
                  {
                    key: 'index',
                    header: '#',
                    align: 'left',
                    width: 60,
                    render: (item, name, index) => index + 1,
                  },
                  {
                    key: 'key',
                    header: 'Key',
                    align: 'left',
                    width: 260,
                  },
                  {
                    key: 'value',
                    header: 'Value',
                    align: 'left',
                    render: ({ value }) => <div className={liststyle.value_td}>{base64decode(value)}</div>,
                  },
                ]}
              />
            </Form.Item>
          )}
          {!detail?.ContractWriteList?.length || (
            <Form.Item label="合约写集">
              <Table
                className={liststyle.table}
                compact={false}
                records={detail?.ContractWriteList || []}
                recordKey="key"
                bordered={true}
                disableTextOverflow={true}
                columns={[
                  {
                    key: 'index',
                    header: '#',
                    align: 'left',
                    width: 60,
                    render: (item, name, index) => index + 1,
                  },
                  {
                    key: 'key',
                    header: 'Key',
                    align: 'left',
                    width: 260,
                  },
                  {
                    key: 'value',
                    header: 'Value',
                    align: 'left',
                    render: ({ value }) => <div className={liststyle.value_td}>{base64decode(value)}</div>,
                  },
                ]}
              />
            </Form.Item>
          )}
          <Form.Item label="合约执行结果">
            <Form.Text>
              <pre className={detailstyle.pre}>{detail?.ContractResult}</pre>
            </Form.Text>
          </Form.Item>
        </Form>
      </div>
    </PageBox>
  );
}
