/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import { createGetChannel, createPostChannel } from './request';
import {
  ChainItem,
  ChainInfo,
  TransactionNumByTime,
  BlockItem,
  Tx,
  ContractItem,
  BlockInfo,
  ContractInfo,
  TxInfo,
  ChainListParam,
  GetDecimalParam,
  GetTransactionNumByTimeParam,
  GetBlockListParam,
  GetTxListParam,
  GetContractListParam,
  GetOrgListParam,
  OriginItem,
  UserItem,
  GetUserListParam,
  SearchParam,
  SearchInfo,
  GetEventListParam,
  EventItem,
  Subscribe,
  CancelSubscribeParam,
  GetNodeListParam,
  NodeItem,
  DeleteSubscribeParam,
} from '@src/models';
import { Fetch, ResponseData, ResponseList } from './type';
// import { ChainListParam } from '@src/models';

// 链配置是否显示
export const GetChainConfig: Fetch<{}, ResponseData<boolean>> = createGetChannel('GetChainConfig');

// 查看链列表
export const GetChainList: Fetch<ChainListParam, ResponseList<ChainItem>> = createGetChannel('GetChainList');
// 首页查询
export const Search: Fetch<SearchParam, ResponseData<SearchInfo>> = createGetChannel('Search');

// 首页数据统计
export const Decimal: Fetch<GetDecimalParam, ResponseData<ChainInfo>> = createGetChannel('Decimal');
// 交易统计曲线
export const GetTransactionNumByTime: Fetch<
  GetTransactionNumByTimeParam,
  ResponseList<TransactionNumByTime>
> = createGetChannel('GetTransactionNumByTime');

// 组织列表
export const GetOrgList: Fetch<GetOrgListParam, ResponseList<OriginItem>> = createGetChannel('GetOrgList');

// 用户列表
export const GetUserList: Fetch<GetUserListParam, ResponseList<UserItem>> = createGetChannel('GetUserList');

// 节点列表
export const GetNodeList: Fetch<GetNodeListParam, ResponseList<NodeItem>> = createGetChannel('GetNodeList');

// 查看区块列表
export const GetBlockList: Fetch<GetBlockListParam, ResponseList<BlockItem>> = createGetChannel('GetBlockList');

// 查看交易列表
export const GetTxList: Fetch<GetTxListParam, ResponseList<Tx>> = createGetChannel('GetTxList');

// 合约列表
export const GetContractList: Fetch<GetContractListParam, ResponseList<ContractItem>> = createGetChannel(
  'GetContractList',
);

//  查询区块详情
export const GetBlockDetail: Fetch<any, ResponseData<BlockInfo>> = createGetChannel('GetBlockDetail');

// 查询交易详情

export const GetTxDetail: Fetch<any, ResponseData<TxInfo>> = createGetChannel('GetTxDetail');
// 合约详情
export const GetContractDetail: Fetch<any, ResponseData<ContractInfo>> = createGetChannel('GetContractDetail');
// 事件列表
export const GetEventList: Fetch<GetEventListParam, ResponseList<EventItem>> = createGetChannel('GetEventList');
// 订阅链（绑定链）
export const SubscribeChain: Fetch<Subscribe, ResponseData<ChainItem>> = createPostChannel('SubscribeChain');
// 取消订阅信息
export const CancelSubscribe: Fetch<CancelSubscribeParam, ResponseData<ChainItem>> = createPostChannel(
  'CancelSubscribe',
);
// 修改订阅信息
export const ModifySubscribe: Fetch<Subscribe, ResponseData<ChainItem>> = createPostChannel('ModifySubscribe');
// 链删除
export const DeleteSubscribe: Fetch<DeleteSubscribeParam, ResponseData<string>> = createPostChannel('DeleteSubscribe');
