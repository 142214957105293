/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import React, { useEffect, useMemo, useState } from 'react';
import PageBox from '@components/ui/PageBox';
import { Form, Bubble } from 'tea-component';
import detailstyle from '../../detail.module.scss';
import { GetContractDetail } from '@src/utils/apis';
import { ContractInfo } from '@src/models';
import { useParams } from 'react-router';
import Transaction from '../components/Transaction';
import Event from '../components/Event';
import style from './index.module.scss';
import { formatUnixTime } from '@src/utils/tools';
import { useSelector } from 'react-redux';
import { RootReducer } from '@src/store';
import { Link } from 'react-router-dom';

export default function ContractDetail() {
  const { contractName } = useParams();
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId = useMemo(() => currentChain?.ChainId, [currentChain]);
  const authType = useMemo(() => currentChain?.AuthType, [currentChain]);
  const [detail, setDetail] = useState<ContractInfo | null>(null);
  const [nav, setNav] = useState<string>('deal');
  useEffect(() => {
    GetContractDetail({ ChainId: chainId, ContractName: contractName }).then((res) => {
      if (res.Data) {
        setDetail(res.Data);
      }
    });
  }, [chainId, contractName]);
  return (
    <PageBox title="合约详情">
      <div className={`${detailstyle.detail} ${style.detail}`}>
        <Form.Title>合约基本信息</Form.Title>
        <Form hideLabel={false} fixedLabelWidth={100} layout="inline">
          <Form.Item label="合约名称">
            <Form.Text>{detail?.Name || '--'}</Form.Text>
          </Form.Item>
          <Form.Item label="合约状态">
            <Form.Text>{detail?.ContractStatusText || '--'}</Form.Text>
          </Form.Item>
          <Form.Item style={{ width: '100%' }} label="合约地址">
            <Form.Text>{detail?.Addr || '--'}</Form.Text>
          </Form.Item>
          <Form.Item label="当前版本">
            <Form.Text>{detail?.Version || '--'}</Form.Text>
          </Form.Item>
          <Form.Item label="虚拟机类型">
            <Form.Text>{detail?.RuntimeType || '--'}</Form.Text>
          </Form.Item>
          <Form.Item label="创建用户">
            <Form.Text>
              <Bubble content={detail?.CreatorAddr || ''}>
                {authType === 'permissionedwithcert' ? detail?.Creator || '--' : detail?.CreatorAddr || '--'}
              </Bubble>
            </Form.Text>
          </Form.Item>
          <Form.Item label="创建时间">
            <Form.Text>{detail?.CreateTimestamp ? formatUnixTime(detail.CreateTimestamp) : '--'}</Form.Text>
          </Form.Item>
          <Form.Item style={{ width: '100%' }} label="创建交易id">
            <Form.Text>
              {detail?.CreateTxId ? (
                <Link to={`/${chainId}/transaction/${detail.CreateTxId}`}>{detail.CreateTxId}</Link>
              ) : (
                '--'
              )}
            </Form.Text>
          </Form.Item>
        </Form>
      </div>
      <div className={style.navs}>
        <div
          onClick={() => {
            setNav('deal');
          }}
          className={`${style.nav} ${nav === 'deal' ? style.act : ''}`}
        >
          交易信息
        </div>
        <div
          onClick={() => {
            setNav('event');
          }}
          className={`${style.nav} ${nav === 'event' ? style.act : ''}`}
        >
          事件信息
        </div>
      </div>
      {nav === 'deal' && <Transaction />}
      {nav === 'event' && <Event />}
    </PageBox>
  );
}
